<template>
  <div class="app-header">
    This is some header. Not sure if we need it
  </div>
</template>

<script>
export default {
  name: "AppHeader"
}
</script>

<style scoped>
.app-header {
  width: 100vw;
  background-color: gray;
}
</style>