<template>
  <div class="app-footer text-muted text-center">
    Made by
    <a href="https://github.com/Wouter-vd-Bemd">Wouter van den Bemd</a>,
    <a href="https://github.com/daria-mih">Daria Mikhailovskaia</a> and
    <a href="https://github.com/SquarePlayn">Pieter Voors</a>
    as an assignment for the
    <a href="https://www.tue.nl/">Technical University Eindhoven</a>.
  </div>
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style scoped>
.app-footer {
}

a {
  color: #2c3e50;
}
</style>